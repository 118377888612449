<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <!-- Spacer -->
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- game name -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('GAME_NAME')"
                      label-for="id-gamename"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('GAME_NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-gamename"
                          v-model="competition.c_string_swe"
                          maxlength="300"
                          :placeholder="$t('GAME_NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Hint -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('HINT')"
                      label-for="id-hint"
                    >
                      <b-form-input
                        id="id-hint"
                        v-model="competition.hint"
                        maxlength="45"
                        :placeholder="$t('HINT')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- company -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="company"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="companyname"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="companies"
                          @input="handleChangeCompany($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Project -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('Project')"
                      label-for="id-project"
                    >
                      <v-select
                        id="id-project"
                        v-model="project"
                        :placeholder="$t('SELECT_PROJECT')"
                        label="name"
                        :clearable="false"
                        class="flex-grow-1"
                        :options="projects"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Teams -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('TEAMS')"
                      label-for="id-teams"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('TEAMS')"
                        rules="required"
                      >
                        <v-select
                          id="id-teams"
                          v-model="gameType"
                          :placeholder="$t('SELECT_TEAMS')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="gameTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Language -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >
                        <v-select
                          id="id-language"
                          v-model="language"
                          :options="languageOptions"
                          label="name"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- strat date -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('START_DATE')"
                      label-for="id-strat-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('START_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-strat-date"
                          v-model="competition.startdate"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- end date -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('END_DATE')"
                      label-for="id-end-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('END_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-end-date"
                          v-model="competition.enddate"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- TimeZone -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('TIME_ZONE')"
                      label-for="id-timezone"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('TIME_ZONE')"
                        rules="required"
                      >
                        <v-select
                          id="id-timezone"
                          v-model="competition.timezone"
                          :placeholder="$t('TIME_ZONE')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="timezoneOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Scope name -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('SCOPE')"
                      label-for="id-scope"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SCOPE')"
                        rules="required"
                      >
                        <v-select
                          id="id-scope"
                          v-model="scope"
                          :placeholder="$t('SELECT_SCOPE')"
                          :clearable="false"
                          class="flex-grow-1"
                          label="name"
                          :options="scopeOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- vision-statement -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('VISION_STATEMENT')"
                      label-for="mc-vision-statement"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('VISION_STATEMENT')"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-vision-statement"
                          v-model="competition.visionstatement"
                          maxlength="800"
                          :placeholder="$t('VISION_STATEMENT')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- show welcomePanel -->
                  <b-col
                    md="3"
                    lg="3"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-10"
                        v-model="competition.show_welcomePanel"
                        name="checkbox-10"
                        class="mt-3"
                        value="1"
                        unchecked-value="0"
                      >
                        {{ $t('SHOW_WELCOME_PANEL') }}
                      </b-form-checkbox>
                    </b-form-group>

                  </b-col>
                  <b-col
                    md="3"
                    lg="3"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="id-chat-enabled"
                        v-model="competition.chat_enabled"
                        class="mt-3"
                        name="checkbox-chat_enabled"
                        value="1"
                        unchecked-value="0"
                      >
                        {{ $t('CHAT_ENABLED') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- Content Terms -->
                  <b-col
                    md="12"
                    lg="12"
                  >
                    <b-form-checkbox
                      id="switchTemplate"
                      v-model="isTemplate"
                      class="d-flex justify-content-between align-items-center my-1"
                      :checked="false"
                      switch
                      @change="selectAllCheckbox"
                    > {{ $t('CONTENT_TEMPLATE') }}</b-form-checkbox>
                  </b-col>
                  <!-- Field: Game -->
                  <b-col
                    v-show="!isTemplate"
                    lg="6"
                    md="6"
                  >

                    <b-form-group
                      :label="$t('GAME_NAME')"
                      label-for="id-game"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('GAME_NAME')"
                        rules="required"
                      >
                        <v-select
                          id="id-game"
                          v-model="copyCompetition"
                          :placeholder="$t('SELECT_GAME')"
                          label="c_string_swe"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="competitions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Contact Options -->
                  <b-col
                    v-show="!isTemplate"

                    md="6"
                    lg="6"
                    class="mt-3"
                  >
                    <b-form-group
                      label=""
                      label-for="contact-options"
                      label-class="mb-1"
                    >
                      <b-form-checkbox-group
                        id="contact-options"
                        v-model="copyOption"
                        :options="copyOptions"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Category -->
                  <b-col
                    v-show="isTemplate"
                    lg="6"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Category"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('CATEGORY')"
                        label-for="id-category"
                      >
                        <v-select
                          id="id-category"
                          v-model="category"
                          :placeholder="$t('SELECT_CATEGORY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="categoryOptions"
                          @input="fetchTemplates($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group></validation-provider>
                  </b-col>
                  <!-- Field: Template -->
                  <b-col
                    v-show="isTemplate"
                    lg="6"
                    md="6"
                  >

                    <b-form-group
                      :label="$t('CONTENT_OF_THE_GAME')"
                      label-for="id-content"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CONTENT_OF_THE_GAME')"
                        rules="required"
                      >
                        <v-select
                          id="id-content"
                          v-model="template"
                          :placeholder="$t('SELECT_CONTENT')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="templateOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ competition.ID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm,
  BFormGroup, BFormInput, BFormCheckbox,
  BFormCheckboxGroup,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      competition: {
        ID: 0,
        project_id: 0,
        c_string_swe: '',
        color: '#127bdc',
        hint: '',
        companyId: '',
        startdate: '',
        enddate: '',
        show_welcomePanel: false,
        use_external_baseline: 1,
        encrypt_id: '',
        template_id: 0,
        category_id: 0,
        gametype: 0,
        is_private: '',
        visionstatement: constants.GAME_VISION,
        timezone: constants.DEFULT_TIMEZONE,
      },
      timezoneOptions: [],
      copyCompetition: {
        ID: 0,
        c_string_swe: '',
        encrypt_id: '',
      },
      template: {
        ID: 0,
        name: '',
      },
      scope: '',
      scopeOptions: constants.SCOPE,
      language: '',
      languageOptions: constants.LANGUAGES,
      copyOptions: ['Copy Habit & Behaviour', 'Copy group & users', 'Copy kpi'],
      copyOption: [],
      category: {},
      companies: [],
      company: '',
      gameType: '',
      gameTypes: constants.GAME_TYPE,
      project: '',
      projects: [],
      categoryOptions: [],
      templateOptions: [],
      competitions: [],
      required,
      isTemplate: false,
    }
  },
  setup() {
    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      successMessage, errorMessage, showErrorMessage,
    }
  },
  mounted() {
    this.getTimezones()
  },
  created() {
    this.fetchCompany()
    this.fetchCategory()
    this.fetchGames()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('template', ['getAllTemplates', 'getAllCategories']),
    ...mapGetters('company', ['getAllCompanies']),
    ...mapGetters('game', ['getAllGames']),
    ...mapGetters('project', ['getAllProjects']),
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories', 'fetchAllTemplates']),
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('game', ['fetchAllGames', 'createGame', 'copyTask', 'copyKpi', 'copyGroup']),
    ...mapActions('project', ['fetchProjectByCompanyID']),
    selectAllCheckbox(value) {
      this.isTemplate = value
    },
    getTimezones() {
      this.timezoneOptions = moment.tz.names().map(timezone => ({ name: timezone, value: timezone }))
    },
    fetchCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.categoryOptions = this.getAllCategories
        }
      })
    },
    fetchGames() {
      this.fetchAllGames().then(() => {
        this.competitions = this.getAllGames
        if (router.currentRoute.params.id) {
          this.copyCompetition = this.competitions.find(i => i.encrypt_id === router.currentRoute.params.id)
        }
      })
    },
    handleChangeCompany(e) {
      this.projects = []
      this.project = ''
      this.fetchProjects(e.companyID)
    },
    fetchTemplates(e) {
      this.templateOptions = []
      this.template = ''
      if (e.ID) {
        const params = `?category_id=${e.ID}&type=game`
        this.fetchAllTemplates(params).then(response => {
          if (response) {
            this.templateOptions = this.getAllTemplates.data
          }
        })
      }
    },
    fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.companies = this.getAllCompanies
          if (this.competition.ID) {
            this.company = this.companies.find(i => i.companyID === this.competition.companyId)
          } else if (this.companies.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.company = this.companies[0]
          }
          this.fetchProjects(this.competition.companyId)
        }
      })
    },
    async fetchProjects(id) {
      await this.fetchProjectByCompanyID(id).then(() => {
        this.projects = this.getAllProjects
      })
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.competition.companyId = this.company.companyID
          this.competition.gametype = this.gameType.id
          this.competition.template_id = this.template && this.template.ID !== null ? this.template.ID : 0
          this.competition.category_id = this.category && this.category.ID !== null ? this.category.ID : 0
          this.competition.project_id = this.project && this.project.ID !== null
            ? this.project.ID
            : 0
          this.competition.is_private = this.scope.id
          this.competition.timezone = this.competition.timezone.name
          this.competition.language = this.language.id
          if ((typeof this.template === 'undefined' || this.template.ID === 0) && this.isTemplate) {
            this.errorMessage('Select the games content according to your preferences, knowing that you can modify it even after the game is created')
          } else {
            this.createGame(this.competition).then(response => {
              if (response) {
                const data = { competitionID: this.copyCompetition.ID, newCompetitionID: response.data.ID }
                if (this.copyCompetition.ID) {
                  if (this.copyOption.includes('Copy Habit & Behaviour')) {
                    this.copyTask(data).then(() => { })
                  }
                  if (this.copyOption.includes('Copy group & users')) {
                    this.copyGroup(data).then(() => { })
                  }
                  if (this.copyOption.includes('Copy kpi')) {
                    this.copyKpi(data).then(() => { })
                  }
                }
                this.$router.push({ name: 'game-list' })
                this.successMessage(this.$i18n.t('MESSAGE.GAME_CREATED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
